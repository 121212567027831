import { Container, Environment } from '@ivorobioff/shared';
import axios, { AxiosInstance } from 'axios';

export interface SuggestedExpense {
  cost: number;
  category: 'food' | 'utilities' | 'fuel' | 'other';
  notes: string;
}

export default class PocService {
  private client: AxiosInstance;

  constructor(container: Container) {
    const { pocBaseUrl, pocAuthKey } = container.get<Environment>('env');
    this.client = axios.create({
      baseURL: pocBaseUrl,
      headers: { Authorization: pocAuthKey }
    });
  }

  async getSuggestedExpenses(prompt: string) {
    const { data } = await this.client.post<SuggestedExpense[]>('/blin/ai/resolve-expenses', { prompt });

    return data;
  }
}